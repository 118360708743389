nav li {
  display: inline-block;
  margin: 0 25px;
  font-weight: 700;
}

nav a {
  color: var(--primary);
  text-decoration: none;
  display: flex;
  align-items: center;
}

nav a span {
  margin-left: 15px;
}