.connect-address {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4px);
  border-radius: 24px;
  padding: 12px;
}

.connect-address aside {
  display: flex;
  align-items: center;
}

.connect-address p {
  font-weight: 700;
  text-transform: capitalize;
  cursor: default;
}

.connect-address .circle {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid var(--secondary);
  border-radius: 50%;
  background-color: transparent;
  margin-right: 10px;
}

.connect-address .circle.connected {
  background-color: var(--secondary);
}