.branding {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: transparent;
  background-image: url(./brandin-background.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 200px;
}

.branding > section {
  margin: 10px;
}