
.introduction h1 {
  font-size: 3rem;
  font-weight: 700;
}

.introduction h1 span:first-child {
  color: var(--secondary);
}

.introduction h1 span:last-child {
  color: var(--primary);
}

.introduction p {
  font-size: 30px;
  margin-top: 30px;
  font-weight: 700;
}

.get-started {
  margin-top: 100px;
}

.get-started h2 {
  color: var(--secondary);
  font-weight: 700;
  font-size: 42px;
}

.get-started p {
  font-size: 30px;
  font-weight: 700;
}

.get-started ul {
  margin-top: 50px;
}

.get-started ul li {
  display: inline-block;
  margin-right: 50px;
}

.get-started h4 {
  font-weight: 700;
  font-size: 72px;
}