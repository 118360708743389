
header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

main {
  display: grid;
  grid-template-columns: 10% 1fr;
  column-gap: 20px;
  margin-top: 150px;
}

main > aside {
  height: 100%;
  padding: 20px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-transform: uppercase;
  padding-right: 50%;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 7px;
}