
:root {
  --primary:white;
  --secondary: #B7FF8A;
  --background: radial-gradient(72.88% 105.33% at 8.89% 1.94%, #E374C4 0%, #3E369C 100%);
}

html {
  color: var(--primary);
  background: var(--background, #3E369C);
  background-repeat: no-repeat;
  min-height: 100%;
  font-family: 'Quicksand', sans-serif;
  font-size: 18px;
}
